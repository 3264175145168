<template>
  <div class="row pt-7">
    <div class="col-xl-4 border-right">
      <div class="row border-bottom pt-3 pb-3">
        <div class="col-xl-12">
          OA系统：{{user.departName}}
        </div>
      </div>
      <div class="row border-bottom pt-3 pb-3">
        <div class="col-xl-12">
          注册用户：<span v-if="user.days > 0">无限制</span><span v-else>5</span>
        </div>
      </div>
      <div class="row border-bottom pt-3 pb-3">
        <div class="col-xl-12">
          帐户状态：<span class="badge badge-danger" v-if="user.days > 0">VIP</span><span class="badge badge-success" v-else>免费用户</span>
        </div>
      </div>
      <div class="row border-bottom pt-3 pb-3">
        <div class="col-xl-12">
          启用日期：{{user.startDateStr}}
        </div>
      </div>
      <div class="row border-bottom pt-3 pb-3">
        <div class="col-xl-12">
          到期日期：{{user.expDateStr}}
        </div>
      </div>
      <div class="row border-bottom pt-3 pb-3">
        <div class="col-xl-12">
          剩余天数：<span v-if="user.days && user.days > 0">{{user.days}}</span><span v-else>0</span>天
        </div>
      </div>
    </div>
    <div class="col-xl-8">
      <div class="row pt-3 pb-3">
        <div class="col-xl-12 font-weight-bolder">
          欢迎使用科易博OA系统
        </div>
      </div>
      <div class="row pt-3 pb-3">
        <div class="col-xl-12">
          从您注册后即开始享受标准版7天免费试用体验，7天后将降为免费版，您可选择继续使用免费版或升级标准版。
        </div>
      </div>
      <div class="row pt-3 pb-3 font-weight-bolder">
        <div class="col-xl-12">
          当您升级为标准版，即付费版后
        </div>
      </div>
      <div class="row pt-3 pb-3">
        <div class="col-xl-12">
          您将获得更大的存储空间、更多高级个性化功能、及一对一服务顾问支持等。
        </div>
      </div>
      <div class="row pt-3 pb-3">
        <div class="col-xl-12">
          详情可咨询：13500178800
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    user: {
      type: Object
    }
  },
  mounted() {
    this.$emit('send', "4");
  }
}
</script>